<template>
    <div class="anchor-navigation-bar" id="anchor-navigation-bar">
        <div class="container">
            <ul class="nav nav-underline">
                <li class="nav-item" v-for="(item) in source">
                    <a class="nav-link" :class="{ active: scrolledItem == item.index }" @click="scroll(item, $event)">{{
                        item.text }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { AnchorNavigationBarItem } from '~/types/AnchorNavigationBarItem';
const navBarState = useState<AnchorNavigationBarItem[]>('navBarState');
const AnchorNavBarOrder = useState<string[]>('AnchorNavBarOrder')
const { scrollToElement } = useSmoothScroll();
const source = computed(() =>
    navBarState.value.sort((a, b) => a.index - b.index)
);
const scrolledItem = ref(0);
function scroll(item: AnchorNavigationBarItem, event: MouseEvent) {
    const element = document.querySelector(`[index="${item.index}"]`)
    scrolledItem.value = item.index;

    if (element) {
        const hasPaddingMofifications = element.getElementsByClassName('pt-0')?.length ?? 0 > 0;

        if (hasPaddingMofifications) {
            scrollToElement(`#${item.link.replace('#', '_')}`, 220);
            return;
        }
    }

    scrollToElement(`#${item.link.replace('#', '_')}`, 130);
}

onMounted(() => {
    const elementsToListen = document.querySelectorAll("[index]");
    const codenameIdMapState = useState<{ codename: string, id: string }[]>('codenameIdMapState', () => []);
    const idsToListen = codenameIdMapState.value.filter(x => AnchorNavBarOrder.value.includes(x.codename)).map(x => x.id);
    const posXidx: { pos: number, idx: number }[] = [];
    let timeout: NodeJS.Timeout;

    const lastScrollPos = ref(0);

    elementsToListen.forEach(element => {

        if (idsToListen.includes(element.id)) {

            const idx = element.getAttribute('index');

            const hasPaddingMofifications = element.getElementsByClassName('pt-0')?.length ?? 0 > 0;

            scrolledItem.value = idx ? parseInt(idx) : 0;
            posXidx.push({
                pos: element.getBoundingClientRect().top + window.scrollY - (hasPaddingMofifications ? 230 : 140),
                idx: idx ? parseInt(idx) : 0
            });

        }
    });

    const ranges: { min: number, max: number, pos: number }[] = [];

    for (let i = 0; i < posXidx.length; i++) {
        if (i == 0) {
            const nextItem = posXidx[i + 1];

            ranges.push({ min: 0, max: nextItem.pos, pos: posXidx[i].idx });
        }
        else if (i == posXidx.length - 1) {

            const previousItem = posXidx[i - 1]

            ranges.push({ min: previousItem.pos, max: Number.MAX_VALUE, pos: posXidx[i].idx });
        }
        else {

            const previousItem = posXidx[i - 1]
            const nextItem = posXidx[i + 1];

            ranges.push({ min: previousItem.pos, max: nextItem.pos, pos: posXidx[i].idx });
        }

    }

    window.addEventListener('scroll', () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            function getPos(){
                if (lastScrollPos.value < window.scrollY){
                    return window.scrollY + 50;
                }

                return window.scrollY;

            }
            const item = ranges.find(x => x.min <= getPos() && x.max >= getPos());
            if (item && navBarState.value.find(x => x.index == item.pos)) {
                scrolledItem.value = item.pos;
            }

            lastScrollPos.value = window.scrollY;
        }, (100));


    })

});
</script>