<template>
    <div class="global-footer" v-if="data">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <a class="logo" href="/">
                        <NuxtImg provider="kontentAiProvider" class="img-fluid" :src="logo" v-if="logo" />
                    </a>
                    <ul class="footer-list">
                        <li>
                            <span>{{ copyrightText }}</span>
                        </li>
                    </ul>
                </div>
                <div class="col-lg">
                    <div class="text-col">
                        <div class="data-sharing-text">
                            Data sharing enabled by the Global Research and Imaging Platform (GRIP) supported by GRP,
                            LLC.
                        </div>
                        <ul class="footer-list">
                            <li>
                                <div class="trustarc-placeholder" v-html="trustArcBlock"></div>
                            </li>
                            <li v-for="link in data.links">
                                <NavigationLink :model="link" :external="true" />
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { type CallToActionModel, type FooterModel, type NavigationLinkModel } from '~/models';

const siteStore = useSiteStore();
const pageStore = usePageStore();
const trustArcBlock = ref('')

const cfg = siteStore.siteConfig;
const model: FooterModel | undefined = cfg.elements.footer.value.length > 0 ?
    siteStore.getLinkedItem<FooterModel>(cfg.elements.footer.value[0]) : undefined;

const data = computed(() => {
    if (model) {
        return {
            logo: firstOrDefault(model?.elements?.logo?.value),
            links: siteStore.getListAs<NavigationLinkModel>(model?.elements?.navigationLinks?.value),
        };
    }

    return undefined;

})

const copyrightText = computed(() => `© ${new Date().getFullYear()} ACTC. All rights reserved.`);
trustArcBlock.value = siteStore.siteConfig.elements.trustarcCodeBlock.value

const logo = computed(() => {
    if (data.value?.logo?.url) {
        return data.value.logo.url
    }

    return '';
});
</script>