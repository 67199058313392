<template>
    <SmartLinkItemComponent custom-tag="div" class="contact-block" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <div class="container">
            <div class="bg-dark-blue">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="left-col">
                            <SmartLinkStandaloneElement custom-tag="h2" class="title"
                                :codename="type.elements.title.codename">
                                {{ data.title }}
                            </SmartLinkStandaloneElement>
                            <SmartLinkStandaloneElement custom-tag="div" class="description"
                                :codename="type.elements.description.codename">
                                <RichText :value="data.description" />
                            </SmartLinkStandaloneElement>
                        </div>
                    </div>
                    <div class="offset-lg-1 col-lg-6">
                        <div class="right-col" v-if="isSent">
                            <h1>Submitted</h1>
                        </div>
                        <div class="right-col" v-else>
                            <form>
                                <div class="form-group">
                                    <label>Full Name</label>
                                    <input class="form-control" v-model="input.fullName" type="text"
                                        placeholder="Samantha Wheeler" />
                                </div>

                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input class="form-control" v-model="input.email" type="text"
                                        placeholder="samantha@email.com" />
                                </div>

                                <div class="form-group">
                                    <label>What are you contacting about?</label><br>
                                    <template v-for="(item, index) in contactOptions.keys()" :key="index">
                                        <label class="radio">
                                            <input type="radio" name="whatAreYouContactingAbout"
                                                v-model="input.whatAreYouContactingAbout" :value="item" />
                                            <span class="custom-radio"></span>{{ item }}
                                        </label><br>
                                    </template>
                                </div>

                                <div class="form-group">
                                    <button class="btn btn-primary btn-sm" @click="(e) => send(e)">Send</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import type { MailDataRequired } from '@sendgrid/mail';
import { contentTypes, type ContactBlockModel } from '~/models';
import type { AnchorNavigationBarItem } from '~/types/AnchorNavigationBarItem';
const type = contentTypes.contact_block;

const isSent = ref(false);

const props = defineProps<{ model: ContactBlockModel }>();

const data = computed(() => {
    return {
        title: props.model.elements.title.value,
        description: props.model.elements.description.value,
        anchorTagNavigationMenuTitle: props.model.elements.anchorTagNavigationMenuTitle.value,
        anchorTagNavigationShowInNavigation: firstOrDefault(props.model.elements.anchorTagNavigationShowInNavigation.value)
    };
});

const contactOptions = new Map([
    ['General', 'info@a4studydata.org'],
    ['Data-Related Inquries', 'data-sharing-support@atrihub.io'],
    ['Technical Support', 'support@a4studydata.org']
]);

const navBarState = useState<AnchorNavigationBarItem[]>('navBarState');
const AnchorNavBarOrder = useState<string[]>('AnchorNavBarOrder');

if (multipleChoiceToBoolean(props.model.elements.anchorTagNavigationShowInNavigation)) {

    navBarState.value.push({ text: props.model.elements.anchorTagNavigationMenuTitle.value, link: `#${props.model.system.id}`, index: AnchorNavBarOrder.value.indexOf(props.model.system.codename) });
}

const input = {
    fullName: '',
    email: '',
    whatAreYouContactingAbout: ''
}

async function send(event: MouseEvent) {
    event.preventDefault();
    function getText() {
        return `<p>Full Name: ${input.fullName}</p>
        <p>Email: ${input.email}</p>
        <p>What are you contacting about: ${input.whatAreYouContactingAbout}</p>`
    }

    let recipient: string | undefined = contactOptions.get(input.whatAreYouContactingAbout);

    const success = await sendEmail({
        subject: `A4 Study Data Contact - ${input.fullName}`,
        html: getText(),
        to: recipient
    } as MailDataRequired)

    if (success) {
        isSent.value = true;
    }
}
</script>