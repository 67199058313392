<template>
    <SmartLinkElement v-if="model" :codename="contentTypes.call_to_action.elements.link_title.codename"
        :item-id="model.system.id">
        <NuxtLink :class="className" :target="targetName(model)" :to="useCtaLink(model)" :external="true" >
            {{ model.elements.linkTitle.value }}
             <IconDownloadCircle class="icon-download" v-if="hasIcon"></IconDownloadCircle>
        </NuxtLink>
    </SmartLinkElement>
</template>

<script setup lang="ts">
import { contentTypes } from '~/models';
import type { CallToActionModel } from '~/models'

const props = defineProps<{ 
    model: CallToActionModel,
    customClass?: string,
    customTarget?: string,
    hasIcon?: boolean,
    external?:boolean
}>()

const className = computed(() =>
    `${props.customClass ? props.customClass : ''}`
)


function targetName(cta:CallToActionModel){
    return  `${props.customTarget ? props.customTarget : useCtaTarget(cta)}`
}

const hasIcon = computed(() => props.hasIcon ?? false)
</script>